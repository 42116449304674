import React, { useEffect, useState } from 'react';

import MainGrid from './components/MainGrid';
import PageLayout from '../common/components/PageLayout';
import DashboardMenu from './components/DahsboardMenu';

const DashboardPage = () => {
  const [devicesList, setDevicesList] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const response = await fetch('/api/devices');
        if (response.ok) {
          const devices = await response.json();
          setDevicesList(devices);

          // Gather device IDs for the fetchEvents call
          const deviceIds = devices.map((device) => device.id);

          // Call fetchEvents and wait for result

          // Calculate dynamic values for the devices list
          const totalDevices = devices.length;
          const onlineDevices = devices.filter((device) => device.status === 'online').length;
          // eslint-disable-next-line no-use-before-define
          const totalEvents = await fetchEvents(deviceIds);
          // Set data with devices and events
          setData([
            {
              title: 'All Devices',
              value: totalDevices.toString(),
              interval: 'Last 30 days',
              trend: 'up',
            },
            {
              title: 'Devices Online',
              value: onlineDevices.toString(),
              interval: 'Last 30 days',
              trend: onlineDevices >= 300 ? 'up' : 'down',

            },
            {
              title: 'Events Count',
              value: totalEvents.toString(),
              interval: 'Today',
              trend: 'up', // Example trend logic

            },
          ]);
        } else {
          throw new Error(await response.text());
        }
      } catch (error) {
        console.error('Failed to fetch devices:', error);
      }
    };

    const fetchEvents = async (deviceIds) => {
      const fromDate = new Date();
      fromDate.setDate(fromDate.getDate() - 1); // Set 'from' to 30 days ago
      const from = fromDate.toISOString();
      const to = new Date().toISOString();

      // Convert device IDs to query parameter format
      const deviceIdParams = deviceIds.map((id) => `deviceId=${id}`).join('&');
      const url = `/api/reports/events?from=${from}&to=${to}&${deviceIdParams}`;

      try {
        const response = await fetch(url, { method: 'GET' });
        if (response.ok) {
          const events = await response.json();
          return events.length; // Return total events count
        }
        throw new Error(await response.text());
      } catch (error) {
        console.error('Failed to fetch events:', error);
        return 0; // Return 0 if there was an error
      }
    };

    fetchDevices();
  }, []);

  return (
    <PageLayout menu={<DashboardMenu />} breadcrumbs={['Dashboard', 'Dashboard']}>

      <div style={{ padding: '20px', backgroundColor: 'white', marginTop: '0' }}>
        <MainGrid devices={devicesList} data={data} />
      </div>
    </PageLayout>
  );
};

export default DashboardPage;
