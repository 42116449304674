import React, { useState } from 'react';
import {
  IconButton, Card, CardActions, CardContent, Typography,
} from '@mui/material';
import GpsFixedIcon from '@mui/icons-material/Garage';
import LocationSearchingIcon from '@mui/icons-material/DriveEta';
import TimeIcon from '@mui/icons-material/AccessTime';
import NearMeIcon from '@mui/icons-material/NearMe';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import {
  formatDistance, formatSpeed, formatHours, formatVolume, formatTime,
} from '../common/util/formatter';
import ReportFilter from './components/ReportFilter';
import { useAttributePreference, usePreference } from '../common/util/preferences';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import ReportsMenu from './components/ReportsMenu';
import ColumnSelect from './components/ColumnSelect';
import usePersistedState from '../common/util/usePersistedState';
import { useCatch, useEffectAsync } from '../reactHelper';
import useReportStyles from './common/useReportStyles';
import MapView from '../map/core/MapView';
import MapRoutePath from '../map/MapRoutePath';
import AddressValue from '../common/components/AddressValue';
import TableShimmer from '../common/components/TableShimmer';
import MapMarkers from '../map/MapMarkers';
import MapCamera from '../map/MapCamera';
import MapGeofence from '../map/MapGeofence';

const columnsArray = [
  ['startTime', 'reportStartTime'],
  ['startOdometer', 'reportStartOdometer'],
  ['startAddress', 'reportStartAddress'],
  ['endTime', 'reportEndTime'],
  ['endOdometer', 'reportEndOdometer'],
  ['endAddress', 'reportEndAddress'],
  ['distance', 'sharedDistance'],
  ['averageSpeed', 'reportAverageSpeed'],
  ['maxSpeed', 'reportMaximumSpeed'],
  ['duration', 'reportDuration'],
];
// const columnsMap = new Map(columnsArray);

const TripReportPage = () => {
  const classes = useReportStyles();
  const t = useTranslation();

  const distanceUnit = useAttributePreference('distanceUnit');
  const speedUnit = useAttributePreference('speedUnit');
  const volumeUnit = useAttributePreference('volumeUnit');
  const hours12 = usePreference('twelveHourFormat');

  const [columns, setColumns] = usePersistedState('tripColumns', ['startTime', 'startAddress', 'endTime', 'endAddress', 'distance', 'averageSpeed']);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [route, setRoute] = useState(null);

  const createMarkers = () => ([
    {
      latitude: selectedItem.startLat,
      longitude: selectedItem.startLon,
      color: 'negative',
    },
    {
      latitude: selectedItem.endLat,
      longitude: selectedItem.endLon,
      color: 'positive',
    },
  ]);

  useEffectAsync(async () => {
    if (selectedItem) {
      const query = new URLSearchParams({
        deviceId: selectedItem.deviceId,
        from: selectedItem.startTime,
        to: selectedItem.endTime,
      });
      const response = await fetch(`/api/reports/route?${query.toString()}`, {
        headers: {
          Accept: 'application/json',
        },
      });
      if (response.ok) {
        setRoute(await response.json());
      } else {
        throw Error(await response.text());
      }
    } else {
      setRoute(null);
    }
  }, [selectedItem]);

  const handleSubmit = useCatch(async ({ deviceId, deviceIds, from, to, type }) => {
    //  If deviceID is empty, set deviceID to deviceIds
    if (!deviceId && deviceIds.length) {
      // If deviceId is not provided, set it to the first deviceId from the deviceIds array
      [deviceId] = deviceIds; // Or handle as needed (e.g., use all deviceIds)
    }
    const query = new URLSearchParams({ deviceId, from, to });
    deviceIds.forEach((id) => {
      query.append('deviceId', id);
    });
    if (type === 'export') {
      window.location.assign(`/api/reports/trips/xlsx?${query.toString()}`);
    } else if (type === 'mail') {
      const response = await fetch(`/api/reports/trips/mail?${query.toString()}`);
      if (!response.ok) {
        throw Error(await response.text());
      }
    } else {
      setLoading(true);
      try {
        const response = await fetch(`/api/reports/trips?${query.toString()}`, {
          headers: { Accept: 'application/json' },
        });
        if (response.ok) {
          setItems(await response.json());
        } else {
          throw Error(await response.text());
        }
      } finally {
        setLoading(false);
      }
    }
  });

  const formatValue = (item, key) => {
    switch (key) {
      case 'startTime':
      case 'endTime':
        return formatTime(item[key], 'minutes', hours12);
      case 'startOdometer':
      case 'endOdometer':
      case 'distance':
        return formatDistance(item[key], distanceUnit, t);
      case 'averageSpeed':
      case 'maxSpeed':
        return formatSpeed(item[key], speedUnit, t);
      case 'duration':
        return formatHours(item[key]);
      case 'spentFuel':
        return formatVolume(item[key], volumeUnit, t);
      case 'startAddress':
        return (<AddressValue latitude={item.startLat} longitude={item.startLon} originalAddress={item[key]} />);
      case 'endAddress':
        return (<AddressValue latitude={item.endLat} longitude={item.endLon} originalAddress={item[key]} />);
      default:
        return item[key];
    }
  };

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle', 'reportTrips']}>
      <div className={classes.container}>
        {selectedItem && (
          <div className={classes.containerMap}>
            <MapView>
              <MapGeofence />
              {route && (
                <>
                  <MapRoutePath positions={route} />
                  <MapMarkers markers={createMarkers()} />
                  <MapCamera positions={route} />
                </>
              )}
            </MapView>
          </div>
        )}
        <div className={classes.containerMain}>
          <div className={classes.header}>
            <ReportFilter handleSubmit={handleSubmit} multiDevice>
              <ColumnSelect columns={columns} setColumns={setColumns} columnsArray={columnsArray} />
            </ReportFilter>
          </div>
          <div className={classes.cardContainer}>
            {!loading ? items.map((item) => (
              <Card key={item.id} className={classes.card}>

                <CardContent>
                  <div className={classes.cardTripWrapper}>
                    <CardActions className={classes.cardAction}>
                      {item.startPositionId ? (
                        selectedItem === item ? (
                          <span className={classes.buttonWrapper}>
                            <IconButton size="80px" color="inherit" className="alertButton" onClick={() => setSelectedItem(null)}>
                              <GpsFixedIcon fontSize="70px" />
                            </IconButton>
                          </span>
                        ) : (
                          <span className={classes.buttonWrapper}>
                            <IconButton size="80px" color="inherit" className="alertButton" onClick={() => setSelectedItem(item)}>
                              <LocationSearchingIcon fontSize="70px" />
                            </IconButton>
                          </span>
                        )
                      ) : null}
                    </CardActions>

                    <div className={classes.cardDetailsWrapper}>
                      <div className="cardTripDetails">
                        <Typography variant="subtitle1" className={classes.cardTripHeading}>
                          Start of Trip
                        </Typography>
                        <div className={classes.cardTripValue}>
                          <Typography variant="body1" className={classes.cardTripValue}>
                            <span className={classes.cardSmallIcoon}>
                              <TimeIcon className={classes.cardIcon} fontSize="inherit" color="primary" />
                            </span>

                            {formatValue(item, 'startTime')}
                          </Typography>
                        </div>
                        <div className={classes.cardTripValue}>
                          <Typography variant="body1" className={classes.cardTripValue}>
                            <span className={classes.cardSmallIcoon}>
                              <NearMeIcon className={classes.cardIcon} fontSize="inherit" color="primary" />
                            </span>

                            {formatValue(item, 'startAddress')}
                          </Typography>
                        </div>
                      </div>

                      <div className="cardTripDetails">
                        <Typography variant="subtitle1" className={classes.cardTripHeading}>
                          End of Trip
                        </Typography>
                        <div className={classes.cardTripValue}>
                          <Typography variant="body1" className={classes.cardTripValue}>
                            <span className={classes.cardSmallIcoon}>
                              <TimeIcon className={classes.cardIcon} fontSize="inherit" color="primary" />
                            </span>

                            {formatValue(item, 'endTime')}
                          </Typography>
                        </div>
                        <div className={classes.cardTripValue}>
                          <Typography variant="body1" className={classes.cardTripValue}>
                            <span className={classes.cardSmallIcoon}>
                              <NearMeIcon className={classes.cardIcon} fontSize="inherit" color="primary" />
                            </span>

                            {formatValue(item, 'endAddress')}
                          </Typography>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div className={classes.cardTripFooter}>
                    <div className={classes.cardTripFooterElement}>
                      <Typography variant="body1" className={classes.cardFooterElement}>

                        <span className={classes.cardSmallIcoon}>
                          <DirectionsCarIcon className={classes.cardIcon} fontSize="inherit" color="primary" />
                        </span>
                        Duration
                      </Typography>
                      <Typography variant="body1" className={classes.cardFooterElement}>
                        {formatValue(item, 'duration')}
                      </Typography>
                    </div>
                    <div className={classes.cardTripFooterElement}>

                      <Typography variant="body1" className={classes.cardFooterElement}>
                        <span className={classes.cardSmallIcoon}>
                          <DirectionsCarIcon className={classes.cardIcon} fontSize="inherit" color="primary" />
                        </span>
                        Distance
                      </Typography>
                      <Typography variant="body1" className={classes.cardFooterElement}>
                        {formatValue(item, 'distance')}
                      </Typography>
                    </div>
                    <div className={classes.cardTripFooterElement}>
                      <Typography variant="body1" className={classes.cardFooterElement}>
                        <span className={classes.cardSmallIcoon}>
                          <DirectionsCarIcon className={classes.cardIcon} fontSize="inherit" color="primary" />
                        </span>
                        Odometer
                      </Typography>
                      <Typography variant="body1" className={classes.cardFooterElement}>
                        {formatValue(item, 'endOdometer')}
                      </Typography>
                    </div>
                    <div className={classes.cardTripFooterElement}>
                      {item.startPositionId ? (
                        selectedItem === item ? (
                          <span className={classes.buttonAlertWrapper}>
                            <IconButton size="80px" color="inherit" className="alertButton" onClick={() => setSelectedItem(null)}>
                              <NearMeIcon fontSize="70px" />
                            </IconButton>
                          </span>
                        ) : (
                          <span className={classes.buttonAlertWrapper}>
                            <IconButton size="80px" color="inherit" className="alertButton" onClick={() => setSelectedItem(item)}>
                              <NearMeIcon fontSize="70px" />
                            </IconButton>
                          </span>
                        )
                      ) : null}
                    </div>
                  </div>
                </CardContent>
              </Card>
            )) : (<TableShimmer columns={columns.length + 1} />)}
          </div>

        </div>
      </div>
    </PageLayout>
  );
};

export default TripReportPage;
