import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { format } from 'date-fns'; // Importing format from date-fns
import { columns as originalColumns } from './gridData';

// Function to convert the date
const formatDate = (dateString) => format(new Date(dateString), "MMMM do yyyy '@' h:mm a");

// Map the original columns to update the date fields
const columns = originalColumns.map((column) => {
  if (column.field === 'lastUpdate') { // Assuming the date field is named "date"
    return {
      ...column,
      renderCell: (params) => formatDate(params.value),
    };
  }
  if (column.field === 'model') {
    return {
      ...column,
      renderCell: (params) => `${params.row.name} - ${params.value}`,
    };
  }
  return column;
});

export default function CustomizedDataGrid({ devices }) {
  return (
    <DataGrid
      rows={devices}
      columns={columns}
      getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
      initialState={{
        pagination: { paginationModel: { pageSize: 20 } },
      }}
      pageSizeOptions={[10, 20, 50]}
      disableColumnResize
      density="compact"
      slotProps={{
        filterPanel: {
          filterFormProps: {
            logicOperatorInputProps: {
              variant: 'outlined',
              size: 'small',
            },
            columnInputProps: {
              variant: 'outlined',
              size: 'small',
              sx: { mt: 'auto' },
            },
            operatorInputProps: {
              variant: 'outlined',
              size: 'small',
              sx: { mt: 'auto' },
            },
            valueInputProps: {
              InputComponentProps: {
                variant: 'outlined',
                size: 'small',
              },
            },
          },
        },
      }}
    />
  );
}
