import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import StatCard from './StatCard';
import CustomizedDataGrid from './CustomizedDataGrid';

// eslint-disable-next-line react/function-component-definition
export default function MainGrid({ devices, data }) {
  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
      {/* cards */}
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Overview
      </Typography>
      <Box sx={{ ml: 2 }}>
        <Grid
          container
          spacing={2}
          columns={12}
          sx={{ mb: (theme) => theme.spacing(2) }}
        >
          {data.map((card) => (
            <Grid key={card.title} xs={6} md={4}>
              <StatCard {...card} />
            </Grid>
          ))}
        </Grid>
      </Box>
      {/* devices */}
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Devices
      </Typography>
      <CustomizedDataGrid devices={devices} />
    </Box>
  );
}
